import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Exclamations`}</strong>{` means to express surprise, strong emotion etc. Naturally, we as student of Arabic grammar would love to know`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`How to express excitement in Arabic`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`What are expression of exasperation in Arabic`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`What is an arabic expression of surprise`}</code></li>
    </ul>
    <br />
    <p>{`May be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Arabic expression of OMG (oh my god)`}</code></p>
    <p>{`and other similar questions...`}</p>
    <br />
    <p>{`Arabic being a vast and diverse language has a concept of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{` Ta'jjub (تعجُّب)`}</code>{` to express similar emotions. We can also see the use of التعجب in Quran,`}</p>
    <ul>
      <li parentName="ul">{`أَبْصِرْ بِهِ وَأَسْمِعْ (Quran 18:26) (How Amazingly well He (Allah) Sees and how Amazingly well He (Allah) can Hear !!)`}</li>
    </ul>
    <br />
    <p>{`So without further ado lets discuss exclamations in Arabic Grammar and make it super easy (إن شاء الله)`}</p>
    <h2>{`How exclamations in Arabic work?`}</h2>
    <p>{`There are certain expressions in Arabic language which we can use to express emotions of excitement like how beautiful, how amazing etc. These expressions describe strong feelings in Arabic language.  `}</p>
    <br />
    <p><strong parentName="p">{`Excited to drill down??`}</strong>{`  `}</p>
    <br />
    <p>{`Let's make the concept of التعجب super easy in Arabic language.  `}</p>
    <p>{`First things first, lets understand the word التعجب. It is a مصدر (masdar) from word  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`تعجَّب  يتَعَجَّب  تعجبْ  التَّعَجُّب`}</code><br parentName="p"></br>{`
`}{`meaning to be amazed or astonished (when we see something amazing or rare)`}</p>
    <br />
    <p>{`In Arabic, we can express تعَجُّب in many ways e.g.`}</p>
    <ul>
      <li parentName="ul">{`By saying Subhan Allah سبحان الله`}</li>
      <li parentName="ul">{`By saying Alhamdu lillah الحمد لله`}</li>
    </ul>
    <p>{`But exclamations in Arabic (تعَجُّب) can also be expressed using certain styles offered by Arabic language. Which we will discuss below`}</p>
    <h2>{`Style of exclamations in Arabic`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`أسلوب التعجب`}</strong></p>
    <br />
    <p>{`There are 2 styles of expressing تعَجُّب or exclamations in Arabic language,`}</p>
    <ul>
      <li parentName="ul">{`ما أفْعَلَ`}</li>
      <li parentName="ul">{`أفْعِل بِه`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.75460122699387%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDklEQVQ4y42U70/aQBjH+///AWZ7YWKyF0tGNDGZbFmyLIDWTaMMgQEzggWjDND+oi1t7+67p9cWrpWoTZ5wved5Pvd873mohuwRQpDJVbZ+3fI89dEKb8ihxb3UyuvnsAJQPXXhRLj5F8FYxJg8CTJgNAvpnUkbku/JjV+uUALTBYx5iCsjQPPGRr05wVGtje5diPPBI876c9Rb9xg+uDKPvwgUG+BRrYO9yjfsfqzi8PsFGr/H2Nk7QKWq46s+wGjqvRFIdc7sCNfTELck796BlN+buFLqmOQPZ4yuJX69KWun0knHcaS5rgvP8+Qe5xwoxa6BhTEojUMCCoJgHez7vgTnnU5ji6Ok5ZtqhXEcw7ZtRFH0bALCMJQ+xlhJcgbMql4nmZYFixKkLCVBhTLymaYpwSJrTM7VOAvAnC642wWWf7AYH0PQL3faYJ5RlJbAliPydying7nRoHvoQ1BuTAzBQ2hsRW2zf4Kbv9C/qGDS+wz3oQ5Yp2B2K/1vZMdzzuigK4o9hT87xmp+gt75J3hTAts6eLxMgI+Aewb77gdq1Xe4bu5j1DmkJJ2q2A5MCvh7uY/LxgfUvrwnsA5hnaRAHvuyfE6GoJeal0hukeTbUjcTyUOS2N7krAZ0BV1S04ZgYd6UdLZkBdS9dM03nwR1nGSl5MtimIxnm6Zs+2Iok14YWnXEtgRL/3916NWYfis9CAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Exclamations in Arabic",
          "title": "Exclamations in Arabic",
          "src": "/static/0557f5b8242df85b23c0339cdf844d88/7c2a6/exclamations-in-arabic.png",
          "srcSet": ["/static/0557f5b8242df85b23c0339cdf844d88/222b7/exclamations-in-arabic.png 163w", "/static/0557f5b8242df85b23c0339cdf844d88/ff46a/exclamations-in-arabic.png 325w", "/static/0557f5b8242df85b23c0339cdf844d88/7c2a6/exclamations-in-arabic.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`ما أفْعَلَ`}</h3>
    <p>{`The sentence made from above expression is a Nominal Sentence or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`جملة اسمية`}</code>{`. For example to say`}</p>
    <ul>
      <li parentName="ul">{`ما أعظمَ العلمَ (Nothing is greater than knowledge)`}
        <ul parentName="li">
          <li parentName="ul">{`which is equal to say, شيء عظيم جعل العلمَ عظيماً`}</li>
        </ul>
      </li>
      <li parentName="ul">{`ما أجمل الليلة (what a beautiful night)`}</li>
      <li parentName="ul">{`Quranic Examples: ما أصبرهم على النار`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Irab of ما أعظمَ العلمَ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`ما`}</strong>{`
اسم تعجب مبني على السكون في محل رفع مبتدأ`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أعظم`}</strong>{`
فعل ماض مبني على الفتح والفاعل ضمير مستتر تقديره هو`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`العلمَ`}</strong>{`
مفعول به منصوب`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أعظمَ العلمَ`}</strong>{`
الجملة الفعلية في محل رفع خبر`}</p>
    </blockquote>
    <h3>{`أفْعِل بِه`}</h3>
    <p>{`The sentence made from above expression is a Verbal Sentence or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`جملة فعلية`}</code>{`. For example to say`}</p>
    <ul>
      <li parentName="ul">{`أعظِمْ بالعلمِ (Nothing is greater than knowledge)`}</li>
      <li parentName="ul">{`أجمل بالليلة (what a beautiful night)`}</li>
      <li parentName="ul">{`Quranic Example: أسمِع بهم وأبصر`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Irab of أعظِمْ بالعلمِ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أعظِم`}</strong>{`
فعل ماض جاء على صيغة الأمر`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الباء`}</strong>{`
حرف جر زائد`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`العلم`}</strong>{`
فاعل مرفوع وعلامة رفعه الضمة المقدرة`}</p>
    </blockquote>
    <h2>{`Rules of Exclamations in Arabic`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`شروط فعل التعجب`}</strong></p>
    <ol>
      <li parentName="ol">{`Arabic Verb should be three lettered i.e فعل ثلاثي`}
        <ul parentName="li">
          <li parentName="ul">{`For example, we can't make التعجب from استغفر. But we can say, ما `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`أعظمَ`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`استغفارَ`}</strong>{` المؤمن where استغفارَ is مصدر of استغفر`}</li>
        </ul>
      </li>
      <li parentName="ol">{`It has to be مُتَصَرِّف i.e. should have ماض, مضارع etc`}
        <ul parentName="li">
          <li parentName="ul">{`For example, we can't use ليس Or نعم OR بئس`}</li>
        </ul>
      </li>
      <li parentName="ol">{`We `}<strong parentName="li">{`can't`}</strong>{` use verb with negation i.e. it has to be مُثبَت`}</li>
      <li parentName="ol">{`It has to be capable of comparison i.e. قابِل للمُفاضلة. To understand this lets take an example`}
        <ul parentName="li">
          <li parentName="ul">{`When we say ما أجمل الليلة we mean there is beauty in this night and there is beauty in other nights but beauty of this night is more`}</li>
          <li parentName="ul">{`But with مات, it doesn't have مفاضلة i.e. there no difference between one death or the another one so it is غيرُ قابل للمفاضلة. But we can say, `}</li>
          <li parentName="ul">{`ما أقسى `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`موتَ`}</strong>{` زيدِ (How cruel/harsh is death of Zaid)`}</li>
        </ul>
      </li>
      <li parentName="ol">{`It `}<strong parentName="li">{`can't`}</strong>{` be a verb which can be used in أفعل فعلاء form. E.g. أبيض بيضاء OR أعمى عمياء. But we can say,`}
        <ul parentName="li">
          <li parentName="ul">{`ما أشد `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`بياضَ`}</strong>{` الثوب`}</li>
          <li parentName="ul">{`ما أشد `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`عَمَى`}</strong>{` الكافر`}</li>
          <li parentName="ul">{`ما أنقى `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`بياض`}</strong>{` الثوب`}</li>
        </ul>
      </li>
    </ol>
    <br />
    <p>{`That's all folks !!`}<br parentName="p"></br>{`
`}{`Thats pretty neat summary of how exclamations in Arabic work.`}</p>
    <br />
    <p>{`But like I see you people are utmost sincere and patient.Remember, `}<strong parentName="p">{`Allah is with the patient`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ان الله مع الصابرين`}</code>{` (Ref 2:153). I would like to cover two more emotions viz,`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`الإغراء`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`التحذير`}</strong></li>
    </ul>
    <h2>{`الإغراء`}</h2>
    <p>{`الإغراء comes from the word  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`أغرى  يغري  أغر  الإغراء`}</code>{`   `}</p>
    <p>{`meaning `}<strong parentName="p">{`to advice`}</strong>{` or `}<strong parentName="p">{`encourage`}</strong>{` for something or `}<strong parentName="p">{`to incite/instigate`}</strong>{` to do something i.e. `}</p>
    <blockquote>
      <p parentName="blockquote">{`أن أنصحك بشيء جيد لتلزمه أو لتفعله`}</p>
    </blockquote>
    <br />
    <p>{`To express الإغراء we repeat the same word making it منصوب. For example,`}</p>
    <ul>
      <li parentName="ul">{`الصلاةَ الصلاةَ meaning `}<code parentName="li" {...{
          "className": "language-text"
        }}>{` Do not leave Salah OR Take care of Salah`}</code>{`  `}
        <ul parentName="li">
          <li parentName="ul">{`We can also say `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`الزم الصلاةَ`}</code>{` to express the same meaning.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`الدراسةَ الدراسةَ meaning `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Encouragement to study`}</code></li>
    </ul>
    <br />
    <p><strong parentName="p">{`Irab of الصلاةَ الصلاةَ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الصلاةَ`}</strong>{`
مفعول به منصوب لفعل محذوف تقديره الزم
او
منصوب على الإغراء`}</p>
    </blockquote>
    <h2>{`التحذير`}</h2>
    <p>{`التحذير comes form the word  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`حذر يحذر التحذير`}</code><br parentName="p"></br>{`
`}{`i.e. to Warn and is opposite to `}<strong parentName="p">{`الإغراء`}</strong></p>
    <br />
    <p>{`To express التحذير we repeat the same word making it منصوب. For example,`}</p>
    <ul>
      <li parentName="ul">{`اللهَ اللهَ meaning `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Warning to the anger of Allah`}</code>{`   `}
        <ul parentName="li">
          <li parentName="ul">{`We can also say `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`احذروا غضب الله`}</code>{` to express the same meaning.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`الفشلَ الفشلَ meaning `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Warning of failure`}</code></li>
    </ul>
    <br />
    <p><strong parentName="p">{`Irab of اللهَ اللهَ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الله`}</strong>{`
مفعول به منصوب لفعل محذوف تقديره اِحذَر
او
منصوب على التحذير`}</p>
    </blockquote>
    <br />
    <p>{`That's pretty much it people. Now you have a good overview of how to be amazed like an Arab, OR Warn like an Arab or to encourage like an Arab. And if any one asks you why you are making sentences like this, proudly say the phrase`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Because Arab said so !!!`}</strong></p>
    <br />
    <p>{`haha, Jokes apart. That how exclamations in Arabic Grammar works`}</p>
    <h2>{`Exclamations in Arabic Reference(s)`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      